require("@rails/ujs").start()
//require("trix")
//require("@rails/actiontext")
require("turbolinks").start()
require("channels")
require('bootstrap/js/dist/tooltip')
import Vue from 'vue/dist/vue.esm';

window.loading = false;

document.addEventListener("turbolinks:request-start", function(){
    console.log("Loading..")
    window.loading = true;
});


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function deleteCookie(name) { setCookie(name, '', -1); }

function sendEvent(category, action, label = null, value = null){
    try {
      //ga('send','event',category,action,label, value);
      gtag('event', action, {event_category: category, event_label: label, event_value: value})
    } catch(e){
        console.error("Could not measure");
    }
}

function showPopup(message){
    console.log("Showing")
    $('body').append(`
    <div class='popup-overlay'>
        <div class="popup">
            <div class="popup-close">&times;</div>
            <div class="popup-content">${message}</div>
        </div>
    </div>
    `)
    document.querySelector('.popup-overlay').addEventListener('click', hidePopup)
    document.querySelector('.popup-close').addEventListener('click', hidePopup)
}

function hidePopup(){
    var overlay = document.querySelector('.popup-overlay')
    if(overlay) {
        $(overlay).remove()
    }
}


function setupKeyboard(){
    if(document.querySelector('.keyboard-app')){
        var keyboard = new Vue({
            el: ".keyboard-app",
            data: {
                letters: [],
                emptyText: "",
                messages: "",
                currentLetter: "",
                focused: false,
                currentName: "",
                currentAudio: "",
                currentState: "",
                currentLink: "",
                currentLinkText: "",
                currentDescription: "",
                renderCount: 0,
            },
            methods: {
                letterClass(i){
                    if(i==0){
                        return "first-letter";
                    } else if(i == this.letters.length-1){
                        return "last-letter"
                    }
                    return ""
                },
                setFocus(){
                    document.querySelector('.invisible-keyboard').focus()
                    sendEvent("keyboard","Focus");
                },
                blurred(){
                    this.focused = false;
                },
                scrollIt() {
                    console.log("Scrolling...")
                    this.focused = true;
                    window.scrollTo(0, 250);
                },
                keyup(e) {
                    if(e.key!="Shift"){
                        this.renderCount+=1;
                        let mobile = false;
                        if (e.keyCode == 0 || e.keyCode == 229) {
                            mobile = true;
                        }
                        let self = this;
                        let fullString = "";
                        if (mobile) {
                            fullString = document.querySelector(".invisible-keyboard").value.toLowerCase();
                        } else {
                            fullString = self.emptyText.toLowerCase();
                        }
                        if (self.letters.length > fullString.length) {
                            self.letters.pop();
                            let nextLetter = "";
                            nextLetter = fullString.charCodeAt(fullString.length - 1);
                            self.currentLetter = String.fromCharCode(nextLetter);
                        } else {
                            let nextLetter = "";
                            nextLetter = fullString.charCodeAt(fullString.length - 1);
                            self.currentLetter = String.fromCharCode(nextLetter);
                            if (nextLetter >= 97 && nextLetter <= 122 || (nextLetter >= 48 && nextLetter <= 57)) {
                                self.letters.push(gon.images[nextLetter].photo_url);
                                self.currentDescription = gon.images[nextLetter].description_text;
                                self.currentName = gon.images[nextLetter].name;
                                self.currentState = gon.images[nextLetter].state_name;
                                self.currentLink = gon.images[nextLetter].link;
                                self.currentLinkText = gon.images[nextLetter].link_text;
                                self.currentAudio = gon.images[nextLetter].audio_url;
                                setTimeout(function(){
                                    if(document.querySelector('#keyboardAudio source').src.indexOf("missing.png")==-1) {
                                        document.querySelector('#keyboardAudio').play();
                                    }
                                    const stateDOM = document.querySelector(`.keyboard-map #${self.currentState}`);
                                    Array.from(document.querySelectorAll('polygon')).map(x => x.style.fill = "rgb(255, 220, 236)");
                                    if(stateDOM){
                                        stateDOM.style.fill = "#eeff00";
                                    }
                                },10);
                            } else {
                                self.emptyText = self.emptyText.substring(0,self.emptyText.length-1);
                            }
                        }
                        sendEvent('keyboard','Keypress',self.currentLetter);
                        setTimeout(function(){
                            document.querySelector('.actual-keyboard').scrollLeft = 999999;
                        },10);
                    }
                }
            }
        });
    }
}


document.addEventListener("turbolinks:before-cache", function() {
  // Tear down built stuff and reset things.
})

function toggleMenu() {
    var menu = document.querySelector('.offcanvas-collapse')
    var offcanvasBg = document.querySelector('.offcanvas-bg')
    offcanvasBg.classList.toggle('open')
    menu.classList.toggle('open')
}

function offCanvas(){
    var offcanvasBg = document.querySelector('.offcanvas-bg')
    offcanvasBg.addEventListener('click', toggleMenu)
    document.querySelectorAll('[data-toggle="offcanvas"]').forEach((x) => {
        x.addEventListener('click', toggleMenu)
    })
}

function stateCircle(){
  let indiaCircle = document.querySelector('.india-circle-container')
  if(indiaCircle){
      console.log(gon.state_name);
      if(gon.state_name && indiaCircle.querySelector(`#${gon.state_name}`)){
          indiaCircle.querySelector(`#${gon.state_name}`).style.fill = "#eeff00"
          indiaCircle.querySelector(`#im_state_name`).innerHTML = gon.state_name.replace("_"," ")
      }

      if(gon.state_name == "No_State"){
          indiaCircle.querySelector(`#im_state_name`).innerHTML = "";
      }
  }
}

function CardRectangle (x, y, w, h) {

    this.x = x;
    this.y = y;
    this.width = w;
    this.height = h;

    this.contains = function (x, y) {
        return this.x <= x && x <= this.x + this.width &&
               this.y <= y && y <= this.y + this.height;
    }
}

function setupCardClickHack(){

    $('.card-columns').click(function(e){
        console.log(e.target)
        if(!e.target.classList.contains('card-columns')){
            return;
        }
        window.cardCoordinates = [];
        $('.card-columns .masonry-card').map(function(k,v){
            var rect = new CardRectangle($(v).offset().left, $(v).offset().top, $(v).width(), $(v).height())
            var card = {card: $(v), rect: rect}
            window.cardCoordinates.push(card)
        });

        window.cardCoordinates.forEach(function(card){
            if(card.rect.contains(e.pageX, e.pageY)){
                console.log("Clicking")
                Turbolinks.visit(card.card.attr('href'))
            }
        });
    });
}

function foodStoriesApp(){

    if(document.querySelector('.food-stories-app')){
        var stateLocations = {
            "nw": ["Jammu and Kashmir", "Punjab", "Haryana", "Rajasthan","Gujarat", "Uttarakhand", "Himachal Pradesh", "Uttar Pradesh", "Bihar", "Sikkim", "Manipal", "Mizoram", "Arunachal Pradesh", "Meghalaya", "Nagaland", "Tripura", "Maharashtra", "Kerala", "Goa", "Karnataka", "Telangana","Tamil Nadu", "Andhra Pradesh", "West Bengal", "Odisha", "Jharkhand", "Chhatisgarh"]
        };
        window.foodStoriesApp = new Vue({
            el: ".food-stories-app",
            data: {
                activeSections: [],
                locale: gon.locale,
                sections: gon.sections,
                posts: gon.posts,
                podcasts: gon.podcasts,
                videos: gon.videos,
                searchPhrase: "",
                state: "",
                activeStates: [],
                showElements: [],
            },
            methods: {
                isTypeActive(t){
                    return this.showElements.indexOf(t)>-1
                },
                clearFilters() {
                    this.showElements = []
                    this.activeSections = []
                    this.activeStates = []
                    this.searchPhrase = ""
                    this.state = ""
                    Array.from(document.querySelectorAll('.stories-map polygon')).map(x => x.style.fill = "rgb(255, 220, 236)")
                    Array.from(document.querySelectorAll('.stories-map path')).map(x => x.style.fill = "rgb(255, 220, 236)")
                },
                canShowType(t){
                    return this.isTypeActive(t)
                },
                toggleType(t){
                    if(this.showElements.indexOf(t)>-1){
                        this.showElements = this.showElements.filter(x => x!=t)
                    } else {
                        this.showElements = [t]
                    }
                },
                toggleSection(s){
                    this.state = "";
                    if(this.activeSections.indexOf(s)>-1){
                        this.activeSections = this.activeSections.filter(x => x != s);
                    } else {
                        this.activeSections.push(s);
                    }
                    this.displayStates()
                    sendEvent("stories","Section",s)
                },
                statesForLocation(l){
                    return stateLocations[l].filter(x => this.activeStates.indexOf(x)>-1)
                },
                displayStates(){
                    this.activeStates = this.matchingPosts.map(x => x.state_name)
                    this.matchingPodcasts.map(x => this.activeStates.push(x.state_name))
                    this.matchingVideos.map(x => this.activeStates.push(x.state_name))
                    this.activeStates = this.activeStates.filter(onlyUnique)
                    const self=this
                    Array.from(document.querySelectorAll('.stories-map polygon')).map(x => x.style.fill = "rgb(255, 220, 236)")
                    Array.from(document.querySelectorAll('.stories-map path')).map(x => x.style.fill = "rgb(255, 220, 236)")
                    this.activeStates.forEach(x => {
                        const stateDOM = document.querySelector(`.india-map-container #${x.replace(' ','_')}`)
                        if(stateDOM){
                            console.log(stateDOM.id)
                            stateDOM.style.fill = "#eeff00"
                        }
                    });

                },
                sectionActive(s){
                    return this.activeSections.includes(s);
                },
                setState(state){
                    this.state = state.replace(/_/g,' ');
                    this.activeSections = [];
                    this.activeStates = [];
                    const self=this;
                    console.log(self.state);
                    console.log(`.india-map-container #${self.state}`);
                    var augState = self.state.replace(/\s/g,'_');
                    const stateDOM = document.querySelector(`.india-map-container #${augState}`);
                    console.log(stateDOM);
                    Array.from(document.querySelectorAll('.stories-map polygon')).map(x => x.style.fill = "rgb(255, 220, 236)")
                    Array.from(document.querySelectorAll('.stories-map path')).map(x => x.style.fill = "rgb(255, 220, 236)")

                    if(stateDOM){
                        stateDOM.style.fill = "#eeff00";
                    }
                    sendEvent("stories","State",self.state)

                    var total = this.matchingPosts.length + this.matchingVideos.length + this.matchingPodcasts.length;
                    if(total == 0){
                        showPopup("Ooops! Looks like we haven't been here yet! Don't worry, we'll get there soon! Meanwhile you can write to us, help us explore it - connect us to people, stories, food and places <a href='mailto:info@foodforward.in'>info@foodforward.in</a>")
                    }
                },
                boggle(){
                    console.log("BOGGLE");
                }
            },
            mounted: function(){
                this.displayStates()
                $('.filter.dropdown').click(function(){
                    console.log("X")
                    $(this).find('.filter-dropdown-menu').toggle()
                })
                setupCardClickHack()
            },
            computed: {
                isFullReset(){
                    return this.activeSections.length == 0 && this.showElements.length == 0;
                },
                storiesCount() {
                    return this.matchingPosts.length + this.matchingVideos.length + this.matchingPodcasts.length;
                },
                storiesLabel() {
                    return (this.matchingPosts.length  + this.matchingVideos.length + this.matchingPodcasts.length) == 1 ? "story" : "stories";
                },
                matchingVideos(){
                    if(!this.canShowType('videos') && !this.isFullReset && !this.isTypeReset){
                        return [];
                    }
                    var self = this
                    if(self.searchPhrase != null && self.searchPhrase.length > 0){
                        return self.videos.filter(function(x){
                            return x.searchable.toLowerCase().indexOf(self.searchPhrase.toLowerCase()) > -1
                        });
                    }
                    if(this.state == ""){
                        const result = [];
                        this.videos.forEach(video => {
                            const tags = video.tag_list.map(x => x.toLowerCase())
                            if(this.isSectionReset){
                                result.push(video);
                            } else {
                                this.activeSections.forEach(section => {
                                    if(tags.includes(section.toLowerCase())){
                                        result.push(video)
                                    }
                                })
                            }
                        })
                        return result.filter(onlyUnique)
                    } else {
                        return this.videos.filter(x => x.state_name == this.state);
                    }
                },

                matchingPodcasts(){
                    if(!this.canShowType('podcasts') && !this.isFullReset && !this.isTypeReset){
                        return [];
                    }
                    if(this.searchPhrase != null && this.searchPhrase.length > 0){
                        var self = this;
                        return self.podcasts.filter(function(x){
                            return x.searchable.toLowerCase().indexOf(self.searchPhrase.toLowerCase()) > -1
                        });
                    }

                    if(this.state == ""){
                        const result = [];
                        this.podcasts.forEach(podcast => {
                            const tags = podcast.tag_list.map(x => x.toLowerCase())
                            if(this.isSectionReset){
                                result.push(podcast);
                            } else {
                                this.activeSections.forEach(section => {
                                    if(tags.includes(section.toLowerCase())){
                                        result.push(podcast)
                                    }
                                })
                            }
                        })
                        return result.filter(onlyUnique)
                    } else {
                        return this.podcasts.filter(x => x.state_name == this.state);
                    }
                },
                isTypeReset(){
                    return this.showElements.length == 0;
                },
                isSectionReset(){
                    return this.activeSections.length == 0;
                },
                matchingPosts(){
                    if(!this.canShowType('posts') && !this.isFullReset && !this.isTypeReset){
                        return [];
                    }
                    var self = this;
                    if(self.searchPhrase != null && self.searchPhrase.length > 0){
                        return self.posts.filter(function(x){
                            return x.searchable.toLowerCase().indexOf(self.searchPhrase) > -1
                        });
                    }

                    if(this.state == ""){
                        const result = [];
                        this.posts.forEach(post => {
                            const tags = post.tag_list.map(x => x.toLowerCase())
                            if(this.isSectionReset){
                                result.push(post);
                            } else {
                                this.activeSections.forEach(section => {
                                    if(tags.includes(section.toLowerCase())){
                                        result.push(post)
                                    }
                                })
                            }
                        })
                        return result.filter(onlyUnique)
                    } else {
                        return this.posts.filter(x => x.state_name == this.state);
                    }
                }
            }
        })
        
        $('.stories-map svg polygon').filter((y,x) => $(x).attr('id')!="").map((y,x) => $(x).tooltip({title: $(x).attr('id').replace(/_/g," "), placement: "right"}))
        $('.stories-map svg path').filter((y,x) => $(x).attr('id')!="").map((y,x) => $(x).tooltip({title: $(x).attr('id').replace(/_/g," "), placement: "right"}))

        $('.stories-map svg polygon').filter((y,x) => $(x).attr('id')!="" && $(x).attr('id')!=null).map((y,x) => $(x).click(function(){ foodStoriesApp.setState($(x).attr('id')) }))
        $('.stories-map svg path').filter((y,x) => $(x).attr('id')!="" && $(x).attr('id')!=null).map((y,x) => $(x).click(function(){ foodStoriesApp.setState($(x).attr('id')) }))
    }
}


function howToApp(){
    if(document.querySelector('.howto-app')){
        let howToApp = new Vue({
            el: ".howto-app",
            data: {
                section: gon.section,
                sections: gon.sections
            },
            methods: {
                setSection(s){
                    this.section = s
                    sendEvent("how_to_india","Category",s)
                },
            }
        })
    }
}

function navbarShrinker(){
    if(document.querySelector('#navbar-pixel')){
        const navbar = document.querySelector('.ffi-navbar');
        setTimeout(function(){
            const observerElement = document.querySelector('#navbar-pixel')
            const observerOptions = {
                root: null,
                rootMargin: '0px 0px', // important: needs units on all values
                threshold: 0
            }
            const observer = new IntersectionObserver(
                handleIntersect,
                observerOptions
            );
            function handleIntersect(entry) {
                if(!window.loading){
                    console.log(entry[0].intersectionRatio)
                    if (entry[0].intersectionRatio <= 0) {
                        document.body.classList.add('shrunk')
                    } else {
                        document.body.classList.remove('shrunk')
                    }
                }
            }
            observer.observe(observerElement)
        }, 500)
    }
}


function aboutPageAnimation(){

    if(document.querySelector('#globalNarrative')){
        setTimeout(function(){
            const observerElement = document.querySelector('#globalNarrative')
            const observerOptions = {
                root: null,
                rootMargin: '15px 0px', // important: needs units on all values
                threshold: 0
            }
            const observer = new IntersectionObserver(
                handleIntersect,
                observerOptions
            );
            function handleIntersect(entry) {
                if (entry[0].intersectionRatio > 0) {
                    let svg = document.querySelector('#globalNarrative').contentDocument
                    svg.querySelector('#cloud1').classList.add('active')
                    svg.querySelector('#cloud2').classList.add('active')
                    svg.querySelector('#cloud3').classList.add('active')
                    observer.unobserve(entry[0].target)
                }
            }
            observer.observe(observerElement)
        }, 500)
    }
}

function eventCardSetup(){
    if(document.querySelector('.event-cards')){
        if('eventCardApp' in window){
            window.eventCardApp.$destroy()
        }
        window.eventCardApp = new Vue({
            el: ".event-cards",
            data: {
                renderer: 0,
                events: gon.events,
                category_name: gon.category_name,
                popup: false,
                evt: gon.events[0] || {},
            },
            methods: {
                showEvent(e){
                    this.popup = true
                    this.evt = e
                    console.log("Event")
                    this.renderer+=1
                    sendEvent("popup","Popup",e.title)
                },
                hideEvent(){
                    this.popup = false;
                    this.renderer+=1
                },
            },
        })
    }
}

function shareButtons(){
    if(document.querySelector('.share-button')){
        document.querySelectorAll('.share-button').forEach(element => {
            element.addEventListener('click', (e) => {
                let social = element.dataset.social
                let url = window.location.href
                if(social == 'facebook') {
                    window.open('https://www.facebook.com/sharer.php?u=' + url, 'sharer', 'toolbar=0,status=0,width=700,height=500')
                    sendEvent("share","Share",url)
                }
                if(social == 'email') {
                    window.open('mailto:?subject=Check out foodforward.in&body=' + url, 'sharer', 'toolbar=0,status=0,width=700,height=500')
                    sendEvent("share","Share",url)
                }

                if(social == 'twitter') {
                    window.open('https://www.twitter.com/share?url=' + url + "&text=Let's Explore Indian Food %23FoodForwardIndia", 'sharer', 'toolbar=0,status=0,width=700,height=500')
                    sendEvent("share","Share",url)
                }
            })
        })
    }

}

function subTabs(){
    if(document.querySelector('.subtab')){
        let setSubtabActive = (e) => {
            console.log("Boggle")
            console.log(e)
            let activeSelector = document.querySelector('.subtab-select')
            let centerX = e.offsetLeft + e.offsetWidth/2 - activeSelector.offsetWidth/2
            activeSelector.style.transform = `translateX(${centerX}px)`
        };
        let activeSubTab = document.querySelector('.subtab.active')
        setSubtabActive(activeSubTab)

        document.querySelectorAll('.subtab').forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                setSubtabActive(element)
                let container = document.querySelector('.event-subcategory-holder')
                let slug = element.dataset.slug
                history.replaceState({slug: slug}, '', `/events/${slug}`)
                fetch(`/api/event_categories/${slug}`).then((response) => response.json()).then((payload) => {
                    window.eventCardApp.events = payload.events
                    window.eventCardApp.category_name = payload.category_name
                })
                sendEvent("subnav",slug)
                return false;
            })
        })
    }
}

function fadeNeon(){
    document.querySelector('.neon-bubble').style.display = "flex";
    /*if(getCookie('neon')){
        // Do Nothing
    } else {
        setCookie('neon',true,1);
        document.querySelector('.neon-bubble').style.display = "flex";
        setTimeout(function(){
            document.querySelector('.neon-bubble').classList.add('closed')
        },5000);
    }*/
}

function setupVideoPlayer(){
    var player = document.querySelector('.video-player')
    var button = document.querySelector('.video-play')
    var content = document.querySelector('.video-content')

    if(button) {
        button.addEventListener('click', function(){
            player.style.display = "block"
            content.style.display = "none"
        })
    }
}

document.addEventListener('turbolinks:load', function(){
    setTimeout(function(){
        console.log("Loaded..")
        window.loading = false;
    },300);

    navbarShrinker()
    offCanvas()
    setupKeyboard()
    setupVideoPlayer()
    stateCircle()
    eventCardSetup()
    aboutPageAnimation()
    shareButtons()
    subTabs()
    howToApp()
    foodStoriesApp()
    fadeNeon()
    setupPopups()
})

function setupPopups(){
    var hindiLink = document.querySelector('.hindi-link')
    if(hindiLink){
        $(hindiLink).click(function(){
            console.log("Click")
            showPopup("The Hindi version of our website is coming soon")
        })
    }

    var donateLink = document.querySelector('.donate-link')
    if(donateLink){
        $(donateLink).click(function(){
            console.log("Click")
            showPopup("Please reach out to us at <a href='mailto:info@foodforward.in'>info@foodforward.in</a> to know how you can donate")
        })
    }
    $('.trigger-share').click(function(){
        $('.share-overlay').show();
    })

    $('.announcement-popup-close').click(function(){
        $('.announcement-overlay').hide();
    });


    $('.share-popup-close').click(function(){
        $('.share-overlay').hide();
    });
}

document.addEventListener('turbolinks:load', function(){
    /* Split this out into a different js */
    let body = document.querySelector('body.admin-signed-in')
    if(body){
        body.querySelectorAll('.content-block').forEach(function(el){
            let anchor = document.createElement('a')
            anchor.href="javascript:;"
            anchor.classList.add('edit-button')
            anchor.innerText="Edit"
            anchor.addEventListener('click', function(){
                let editorModal = document.querySelector('#editorModal')
            });
            el.prepend(anchor)
            el.classList.add('editable')
        })
    }
})

